import './css/index.css';
import 'components/footer';
import 'components/card-background';
import Mediator from 'js/mediator';
import Navigation from 'components/navigation';

Mediator.create( {

    componentClasses: [
        Navigation
    ],

    init() {

        console.info( 'The 404 Page has initiated.' );

    },

    ready() {

        console.info( 'The 404 Page is ready.' );
        console.info( 'Components:', this.components );

        this.bindSearchLinkClick();

    },

    bindSearchLinkClick() {

        const navSearch = document.querySelector( '.nav-search' );
        const linkSearch = document.querySelector( '.init-search' );

        linkSearch.addEventListener( 'click', () => navSearch.click() );

    }

} );
